<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$router.push({ name: "Home", params: { error: true } });
  },
};
</script>

<style></style>
